import React, {useRef, useState} from "react";
import {ListGroup} from "react-bootstrap";
import Autolink from 'react-native-autolink';
import {Timestamp} from "firebase/firestore";
import {AuthProvider, useAuth} from "../context/AuthContext";
import {IconContext} from "react-icons";
import {FaReply} from 'react-icons/fa';

const Message = ({
    setReply,
    id,
    message = '',
    replyTo = {},
    timestamp = Timestamp.now(),
    type = 'TextMessage',
    username = ''
}) => {

    const {c_username} = useAuth();
    const {row_ref} = useRef();
    const [showReply, setShowReply] = useState(false)

    return(
        <>
            <div onMouseEnter={() => setShowReply(true)} onMouseLeave={() => setShowReply( false)} className={"msg-row " + (c_username === username ? "msg-sender" : '')}>

                {((c_username === username)  && showReply) ?
                    <IconContext.Provider value={{ className: "reply-btn" }}>
                        <div >
                            <FaReply onClick={() => setReply({id: id, username: username, message: message})}/>
                        </div>
                    </IconContext.Provider> : <></> }
                <div className={c_username === username ? "msg-text-sender" : "msg-text"}>
                    <div className="replyWrapper">
                        {replyTo !== {} ? <p className="replyText">{replyTo.message}</p> : <></> }
                    </div>
                    <Autolink
                        // Required: the text to parse for links
                        text={message}
                        hashtag="instagram"
                        style={{color: 'white', fontSize: '18px'}}
                        url
                    />
                </div>

                {(c_username !== username) && showReply ?
                    <IconContext.Provider value={{ className: "reply-btn" }}>
                        <div>
                            <FaReply onClick={() => setReply({id: id, username: username, message: message})} />
                        </div>
                    </IconContext.Provider> : <></> }

            </div>
        </>
    )
}

export default Message;
