import React, {useState, useEffect, useRef} from 'react';
import {AuthProvider, useAuth} from "../context/AuthContext";
import {Link, useNavigate } from "react-router-dom";
import {doc, onSnapshot, getDocs, orderBy, setDoc, collection, getFirestore, query, where, Timestamp} from 'firebase/firestore';

import './Message'

import app from '../firebase';
import Message from "./Message";
import "../Chat.css";
import provider from "react-redux/lib/components/Provider";

const firestore = getFirestore(app);
const messagesRef = collection(firestore,"messages");
const msg_query = query(collection(firestore, "messages"), orderBy("timestamp", "asc"));

export default function Chat() {

    const [error, setError] = useState("")
    const {currentUser, c_username, logout} = useAuth()
    const [messages, setMessages] = useState([])
    const [sendMessage, setSendMessage] = useState('')
    const navigate = useNavigate()

    const [reply, setReply] = useState(null)


    const textArea = useRef();
    const chatbox = useRef();

    async function handleSubmit(e) {
        e.preventDefault()

        if (sendMessage === '') return;

        const local = sendMessage;
        const loc_reply = reply;
        setSendMessage('')
        setReply(null);

        const newMsgReference = doc(messagesRef);

        await setDoc(newMsgReference,
            {
                message: local,
                replyTo: reply == null ? {} : reply,
                storage_location: '',
                timestamp: Timestamp.now(),
                type: 'TextMessage',
                username: c_username
            }
        )


    }

    function keyPressHandler(event) {
        if (event.key === "Enter") {
            //console.log("diwjdjidw");
            if (document.activeElement === textArea.current) {
                handleSubmit(event);
            }
        }
    }

    function DisplayReply(props) {
        console.log(typeof props.replys)
        if (props.replys != null) {
            return (
                    <p className="replyBanner">{ 'Reply to: ' + reply.username + ' ' + reply.message}</p>
            )
        }
        else {
            return null;
        }
    }
    function handleTyping(e) {
        chatbox.current.scrollTop = chatbox.current.scrollHeight;
        setSendMessage(e.target.value)
    }

    async function handleLogout() {
        setError('')

        try {
            await logout()
            navigate('/login')
        }catch {
            setError('Failed to log out')
        }
    }

    useEffect(() => {
        const unsubscribe = onSnapshot(msg_query, (querySnapshot) => {
          const msgs = querySnapshot.docs.map((doc) => ({
             ...doc.data(),
              id: doc.id
          }));

          const newMsgs = messages.concat(msgs);
          setMessages(newMsgs);
          chatbox.current.scrollTop = chatbox.current.scrollHeight;
        })

        return unsubscribe;
    }, [])

    useEffect(async () =>  {
        const que = query(messagesRef, orderBy("timestamp", "asc"))
        const querySnapshot = await getDocs(que);
        const msgs = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
        }))

        const newL = messages.concat(msgs)
        setMessages(newL)
        chatbox.current.focus()
    }, []);

    return(
        <>
            <div className="container">
                <header className="header">
                    <h2>DM Chat</h2>
                </header>
                <div className="chatbox" >
                    <div className="col-1" ref={chatbox}>
                        {
                            messages.map( message => (
                                <Message setReply={setReply} {...message}/>
                            ))
                        }
                        <div className="padd">

                        </div>
                    </div>
                    <DisplayReply replys={reply}/>
                    <form onSubmit={handleSubmit}>
                        <textarea onKeyUp={keyPressHandler} ref={textArea} placeholder="Type your message" value={sendMessage} onChange={handleTyping}/>
                        <button className="sendButton" type="submit" >Submit</button>
                    </form>
                </div>
            </div>
        </>
    )
}
