import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyBE3CUnJJ4X5GKb6h_BdNCwyroPVVTQWWg",
//     authDomain: "dm-app-dev-71092.firebaseapp.com",
//     projectId: "dm-app-dev-71092",
//     storageBucket: "dm-app-dev-71092.appspot.com",
//     messagingSenderId: "405122355255",
//     appId: "1:405122355255:web:0540f58dab43081e2e6fb2"
// };
const firebaseConfig = {
    apiKey: "AIzaSyAvlUTqacaHziXmGBjndczhHNzXHobl9r0",
    authDomain: "dmapp-7b0bd.firebaseapp.com",
    projectId: "dmapp-7b0bd",
    storageBucket: "dmapp-7b0bd.appspot.com",
    messagingSenderId: "572226164689",
    appId: "1:572226164689:web:244faf932213ddd023f2f0"
};

const app = initializeApp(firebaseConfig);
export default app;

