import React, {useRef, useState} from 'react';
import { useAuth } from "../context/AuthContext";
import {Link, useNavigate} from "react-router-dom";

import './login.css'

export default function Login() {


    const emailRef = useRef()
    const passwordRef = useRef()
    const {login, currentUser} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        } catch(e){
            setError('Failed to Sign in')
        }

        setLoading(false)
    }
    return (
        <>
            <div className="container" >
                <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleSubmit}>
                        <label>Email</label>
                        <input type="email" ref={emailRef} required />

                        <label>Password</label>
                        <input type="password" ref={passwordRef} required />

                    <button disabled={loading} className="w-100" type="submit">Login</button>
                </form>
            </div>
        </>
    )
}
