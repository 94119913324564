import React, {useContext, useState, useEffect} from 'react';
import {getAuth,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail
} from "firebase/auth";

import app from "../firebase";


const auth = getAuth(app)
const AuthContext = React.createContext()


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [c_username, setUsername] = useState('')

    // function signup(email, password) {
    //     return createUserWithEmailAndPassword(auth, email, password)
    // }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logout() {
        return signOut(auth)
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email)
    }

    useEffect( () => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)

            if (user !== null) {
                if (user.email === "dominik@mydmapp.com")
                    setUsername("Dominik")
                else if(user.email === "marketa@mydmapp.com")
                    setUsername("Markéta")
            }


            setLoading(false)
        })

        return unsubscribe;
    }, [])


    const value = {
        currentUser,
        c_username,
        login,
        logout,
        resetPassword
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
