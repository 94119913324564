import '../App.css';
import {Container} from "react-bootstrap";
import {AuthProvider} from "../context/AuthContext";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Chat from "./Chat";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";

const App = () => {
    return (
            <div>
                <Router>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<PrivateRoute/>}>
                                <Route path="/" element={<Chat />}/>
                            </Route>
                            <Route path="/login" element={<Login/>}/>
                        </Routes>
                    </AuthProvider>
                </Router>
            </div>

    );
}

export default App;
